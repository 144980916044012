import { bookingsMemberAreaListUpload } from '@wix/bi-logger-wixboost-ugc/v2';
import { Bookings } from '../../types';
import { BiLogger } from './biLoggerFactory';

export const logTabLoaded = (
  tabName: string,
  data: Bookings,
  biLogger?: BiLogger,
) => {
  biLogger?.report(
    bookingsMemberAreaListUpload({
      isEmptyState: !data.groups.length,
      reschedule: data.groups.some((group) => group.allowedActions.reschedule),
      tabName,
    }),
  );
};
