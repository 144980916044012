export enum MemberAreaClickActions {
  EmptyStateButton = 'empty-state-button',
  ViewMoreDetails = 'view-more-details',
  BookAgain = 'book-again',
  Reschedule = 'reschedule',
  Cancel = 'cancel',
  OpenZoomLink = 'open-zoom-link',
}

export enum CancelModalClickValues {
  Reschedule = 'reschedule-bookings',
  Cancel = 'cancel-bookings',
  ExitModal = 'exit-modal',
}

export enum ConferencePlatform {
  ZOOM = 'zoom',
}
