import { ReportProps } from '@wix/yoshi-flow-editor/bi';
import { BiLogger } from '../../../core/bi/biLoggerFactory';
import { ApiContext } from '../../types';

export interface LogBiArgs {
  biLogger?: BiLogger;
  eventParams: ReportProps;
}

export const logBi = async ({
  biLogger,
  eventParams,
}: ApiContext & LogBiArgs): Promise<void> => {
  biLogger?.report(eventParams);
};
