import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';
import { EventNames } from '../../core/fedops';
import { logTabLoaded } from '../../core/bi/logTabLoaded';

export type GetHistory = () => void;

export function createGetHistoryAction({
  context,
  getControllerState,
}: CreateActionParams): GetHistory {
  return async () => {
    const { api, fedopsLogger, biLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.HistoryTabLoaded);

    const [state, setState] = getControllerState();

    const { bookings, selectedTimezone, businessInfo } =
      await api.getEnrichedBookings({
        type: TabTypes.HISTORY,
        applicationState: state,
      });

    logTabLoaded(TabTypes.HISTORY, bookings, biLogger);

    setState({
      historyData: bookings,
      selectedTimezone,
      businessInfo,
    });

    fedopsLogger?.interactionEnded(EventNames.HistoryTabLoaded);
  };
}
