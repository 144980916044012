import { CancellationFeeDetails } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';

export const mockCancellationFeeDetails = ({
  feeDetails = {
    amount: {
      value: '10',
      currency: 'USD',
      formattedValue: '$10',
    },
  },
}: {
  feeDetails?: CancellationFeeDetails;
} = {}): CancellationFeeDetails => {
  return feeDetails;
};
