import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  CancelModalClickValues,
  MemberAreaClickActions,
  TabTypes,
} from '../../types';
import { BiLogger } from './biLoggerFactory';

export function logCancelBi(
  biLogger: BiLogger,
  selectedTab: TabTypes,
  clickValue: CancelModalClickValues,
  confirmation: boolean = false,
) {
  biLogger?.report(
    bookingsLoginUserAccountSeeAllServicesClick({
      action: MemberAreaClickActions.Cancel,
      extraInfo: JSON.stringify({
        confirmation,
        clickValue,
      }),
      referralInfo: selectedTab,
    }),
  );
}
