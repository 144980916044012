import { Order, OrderTransactions } from '@wix/ambassador-ecom-v1-order/types';
import { ApiContext } from '../../types';
import { QueryExtendedBookingResponse } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import _ from 'lodash';

export interface GetOrdersAndTransactionsArgs {
  bookings: QueryExtendedBookingResponse;
}

export const getOrdersAndTransactions = async ({
  api,
  bookings,
}: ApiContext & GetOrdersAndTransactionsArgs): Promise<{
  orders?: Order[];
  transactions?: OrderTransactions[];
}> => {
  const allBookingIds = bookings.extendedBookings?.map((booking) => {
    return {
      bookingId: booking.booking?.id,
      groupId:
        booking.booking?.multiServiceBookingInfo?.id || booking.booking?.id!,
    };
  });
  const bookingIds = _.uniqBy(allBookingIds, (booking) => booking.groupId).map(
    (booking) => booking.groupId,
  );
  const orders = (await api.queryOrders({ bookingIds })).orders;
  const orderIds =
    orders?.filter((order) => !!order.id).map((order) => order.id!) || [];
  const transactions = orderIds.length
    ? (await api.listTransactionsForMultipleOrders({ orderIds }))
        .orderTransactions
    : [];

  return { orders, transactions };
};
